/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {VideoIframe, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!VideoIframe) _missingMdxReference("VideoIframe", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "understanding-hearing-aid-receivers-function-and-replacement",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#understanding-hearing-aid-receivers-function-and-replacement",
    "aria-label": "understanding hearing aid receivers function and replacement permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Understanding Hearing Aid Receivers: Function and Replacement"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For individuals who rely on hearing aids, understanding the intricate components of these devices is crucial to maintaining optimal hearing assistance. One such component is the hearing aid receiver, also known as the speaker or the receiver-in-canal (RIC) in certain types of hearing aids. This small yet vital part plays a significant role in delivering sound from the hearing aid to the wearer's ear canal."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "function-of-hearing-aid-receivers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#function-of-hearing-aid-receivers",
    "aria-label": "function of hearing aid receivers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Function of Hearing Aid Receivers:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The hearing aid receiver is responsible for converting electrical signals into audible sound. Once sounds are captured by the microphone of the hearing aid, they are processed and amplified by the device's circuitry. The amplified signals are then transmitted to the receiver, which converts them back into sound waves. These sound waves are then delivered into the ear canal, allowing the wearer to perceive sound more clearly."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "signs-of-wear-and-when-to-replace",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signs-of-wear-and-when-to-replace",
    "aria-label": "signs of wear and when to replace permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Signs of Wear and When to Replace:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Over time, hearing aid receivers may experience wear and tear due to regular use, exposure to moisture, or other environmental factors. Common signs that indicate a need for replacement include:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Decreased Sound Quality: If you notice a decline in the clarity or volume of sound produced by your hearing aids, it could be due to a failing receiver."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Intermittent Sound: Inconsistent sound output, such as sound cutting in and out, may indicate a problem with the receiver connection."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Physical Damage: Visible signs of damage, such as cracks or breaks in the receiver, are clear indicators that it needs replacement."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Feedback or Whistling: Excessive feedback or whistling noises from the hearing aid may suggest issues with the receiver."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Increased Maintenance: If you find yourself needing frequent repairs or adjustments to your hearing aids, it might be time to consider replacing the receiver."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-replace-the-receiver-of-a-horizon-ax-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-replace-the-receiver-of-a-horizon-ax-hearing-aids",
    "aria-label": "how to replace the receiver of a horizon ax hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to Replace the receiver of a Horizon AX hearing aids:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Follow these steps to replace your Horizon hearing aid receiver:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Grab your hearing aids firmly and pinch the wire from the base. Pull upward until the wire snaps out of place."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Grab the replacement receiver and remove the transport cover from the end that goes into the ear."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Insert the new wire into the empty opening, push until it snaps into place."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Put silicone dome on the end of the receiver that goes in the ear."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Check the video below for a visual representation of how to replace the receiver of a Horizon AX hearing aid."), "\n", React.createElement(VideoIframe, {
    title: "Hear.com | Horizon AX Go – How to Change Receivers",
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/Piq2S_5hVLc?si=Qm-PjnPg6G-uxvYi"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-hearcom-for-assistance",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-hearcom-for-assistance",
    "aria-label": "contact hearcom for assistance permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact Hear.com for Assistance:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you're experiencing issues with your hearing aids or considering an upgrade, our team of hearing specialists at ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), " is here to help. Whether you need assistance with replacing your hearing aid receivers or exploring the latest advancements in hearing aid technology, we're dedicated to providing personalized support and guidance."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Don't let hearing loss hold you back. Contact us today to schedule a consultation and take the first step toward improving your hearing and quality of life."), "\n", React.createElement(LandingPageCta, {
    copy: "Contact our hearing specialists",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
